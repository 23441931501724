import React, { useContext, useState } from "react";
import { HolidaysContext } from "../../context/Admin/HolidaysContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import HolidayForm from "../../components/forms/HolidayForm";

const Holidays = () => {
  const { holidays, addHoliday, editHoliday, deleteHoliday } =
    useContext(HolidaysContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Holiday Name", accessor: "holidayName" },
    { Header: "Date", accessor: "date" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            title="Edit Holiday"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            title="Delete Holiday"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddClick = () => {
    setSelectedHoliday(null);
    setIsModalOpen(true);
  };

  const handleEdit = (holiday) => {
    setSelectedHoliday(holiday);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this holiday?")) {
      deleteHoliday(id);
    }
  };

  const handleFormSubmit = (holiday) => {
    if (selectedHoliday) {
      editHoliday({ ...selectedHoliday, ...holiday });
    } else {
      addHoliday(holiday);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="p-6 bg-white rounded-xl shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Holidays</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 shadow-md"
        >
          Add New Holiday
        </button>
      </div>
      <ReusableTable columns={columns} data={holidays} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={selectedHoliday ? "Edit Holiday" : "Add New Holiday"}
        >
          <HolidayForm
            onSubmit={handleFormSubmit}
            onCancel={() => setIsModalOpen(false)}
            initialValues={selectedHoliday || { holidayName: "", date: "" }}
          />
        </Modal>
      )}
    </div>
  );
};

export default Holidays;
