import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const WorkCategoriesContext = createContext();

const WorkCategoriesProvider = ({ children }) => {
  const [workCategories, setWorkCategories] = useState([]);

  // Fetch all work categories
  const fetchWorkCategories = async () => {
    try {
      const response = await api.get("/work-categories");
      setWorkCategories(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Failed to fetch work categories:", error);
    }
  };

  // Add a new work category
  const addWorkCategory = async (category) => {
    try {
      const response = await api.post("/work-categories", category);
      setWorkCategories((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add work category:", error);
    }
  };

  // Edit an existing work category
  const editWorkCategory = async (category) => {
    try {
      const response = await api.put(`/work-categories/${category.id}`, category);
      setWorkCategories((prev) =>
        prev.map((item) => (item.id === category.id ? response.data : item))
      );
    } catch (error) {
      console.error("Failed to edit work category:", error);
    }
  };

  // Delete a work category
  const deleteWorkCategory = async (id) => {
    try {
      await api.delete(`/work-categories/${id}`);
      setWorkCategories((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete work category:", error);
    }
  };

  useEffect(() => {
    fetchWorkCategories();
  }, []);

  return (
    <WorkCategoriesContext.Provider
      value={{
        workCategories,
        addWorkCategory,
        editWorkCategory,
        deleteWorkCategory,
      }}
    >
      {children}
    </WorkCategoriesContext.Provider>
  );
};

export default WorkCategoriesProvider;
