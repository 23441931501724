import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const EnquiryContext = createContext();

const API_BASE_URL = "http://127.0.0.1:8000/api/enquiries"; // Centralized API base URL

const EnquiryProvider = ({ children }) => {
  const [enquiries, setEnquiries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchEnquiries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(API_BASE_URL, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setEnquiries(response.data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const createEnquiry = async (data) => {
    try {
      const response = await axios.post(API_BASE_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setEnquiries((prev) => [...prev, { ...data, id: response.data.id }]);
    } catch (err) {
      setError(err.message);
    }
  };

  const updateEnquiry = async (id, updatedData) => {
    try {
      await axios.put(`${API_BASE_URL}/${id}`, updatedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setEnquiries((prev) =>
        prev.map((enquiry) =>
          enquiry.id === id ? { ...enquiry, ...updatedData } : enquiry
        )
      );
    } catch (err) {
      setError(err.message);
    }
  };

  const deleteEnquiry = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setEnquiries((prev) => prev.filter((enquiry) => enquiry.id !== id));
    } catch (err) {
      setError(err.message);
    }
  };

  const assignEnquiry = async (id, assignData) => {
    try {
      await axios.post(`${API_BASE_URL}/${id}/assign`, assignData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setEnquiries((prev) =>
        prev.map((enquiry) =>
          enquiry.id === id ? { ...enquiry, ...assignData } : enquiry
        )
      );
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchEnquiries();
  }, []);

  return (
    <EnquiryContext.Provider
      value={{
        enquiries,
        isLoading,
        error,
        createEnquiry,
        updateEnquiry,
        deleteEnquiry,
        assignEnquiry,
      }}
    >
      {children}
    </EnquiryContext.Provider>
  );
};

const useEnquiries = () => {
  return useContext(EnquiryContext);
};

export { EnquiryProvider, useEnquiries };
