import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../pages/Admin/Dashboard";
import Settings from "../pages/Admin/Settings";
import UserManagement from "../pages/Admin/UserManagement";
import Enquiries from "../pages/Admin/Enquiries";
import Quotations from "../pages/Admin/Quotations";
import Projects from "../pages/Admin/Projects";
import SubcontractWork from "../pages/Admin/SubcontractWork";
import Assets from "../pages/Admin/Assets";
import Accounts from "../pages/Admin/Accounts";
import Purchases from "../pages/Admin/Purchases";
import StockManagement from "../pages/Admin/StockManagements";
import Reports from "../pages/Admin/Reports";
import Expenses from "../pages/Admin/Expenses";
import LabourManagement from "../pages/Admin/LabourManagement";
import VehicleManagement from "../pages/Admin/VehicleManagement";
import HR from "../pages/Admin/HR";
import ToolsManagement from "../pages/Admin/ToolsManagement";
import TravelAllowance from "../pages/Admin/TravelAllowance";
import Reminders from "../pages/Admin/Reminders";
import ProjectDocuments from "../pages/Admin/ProjectDocuments";
import DailyTasks from "../pages/Admin/DailyTasks";
import DailyNotes from "../pages/Admin/DailyNotes";
import AttendanceReport from "../pages/Admin/AttendanceReport";
import Clients from "../pages/Admin/Clients";
import SalesCustomers from "../pages/Admin/SalesCustomers";
import Services from "../pages/Admin/Services";
import Suppliers from "../pages/Admin/Suppliers";
import Materials from "../pages/Admin/Materials";
import BankDetails from "../pages/Admin/BankDetails";
import WorkTypes from "../pages/Admin/WorkTypes";
import WorkCategories from "../pages/Admin/WorkCategories";
import ProjectSections from "../pages/Admin/ProjectSections";
import Holidays from "../pages/Admin/Holidays";
import WorkLocations from "../pages/Admin/WorkLocations";
import TaxTypes from "../pages/Admin/TaxTypes";
import ProjectDetails from "../pages/Admin/ProjectDetails";
import SecurityDeposit from "../pages/Admin/SecurityDeposit";
import EMD from "../pages/Admin/EMD";
import Employees from "../pages/Admin/Employees";
import StockManagements from "../pages/Admin/StockManagements";
import SubContractDetails from "../pages/Admin/SubContractDetails";
import { EnquiryProvider } from "../context/Admin/EnquiryContext";
import AssetProvider from "../context/Admin/AssetContext";
import SecurityDepositsProvider from "../context/Admin/SecurityDepositsContext";
import EMDProvider from "../context/Admin/EMDContext";
import AccountsProvider from "../context/Admin/AccountsContext";
import ExpenseBillsProvider from "../context/Admin/ExpenseBillsContext";
import TaxTypesProvider from "../context/Admin/TaxTypesContext";
import WorkLocationsProvider from "../context/Admin/WorkLocationsContext";
import HolidaysProvider from "../context/Admin/HolidaysContext";
import ProjectSectionsProvider from "../context/Admin/ProjectSectionsContext";
import WorkCategoriesProvider from "../context/Admin/WorkCategoriesContext";
import WorkTypesProvider from "../context/Admin/WorkTypesContext";
import BankDetailsProvider from "../context/Admin/BankDetailsContext";
import MaterialProvider from "../context/Admin/MaterialContext";
import SuppliersProvider from "../context/Admin/SuppliersContext";
import ServicesProvider from "../context/Admin/ServicesContext";
import ServiceTypesProvider from "../context/Admin/ServiceTypesContext";
import SalesCustomersProvider from "../context/Admin/SalesCustomersContext";
import ClientsProvider from "../context/Admin/ClientsContext";
import DailyNotesProvider from "../context/Admin/DailyNotesContext";

const AdminRoutes = () => {
    return (
        <Routes>
            {/* Dashboard */}
            <Route
                path="/dashboard"
                element={
                    <ProtectedRoute action="view_reports">
                        <Dashboard />
                    </ProtectedRoute>
                }
            />

            {/* Settings */}
            <Route
                path="/settings"
                element={
                    <ProtectedRoute action="configure_settings">
                        <Settings />
                    </ProtectedRoute>
                }
            />

            {/* User Management */}
            <Route
                path="/users"
                element={
                    <ProtectedRoute action="manage_users">
                        <UserManagement />
                    </ProtectedRoute>
                }
            />

            {/* Enquiries and Quotations */}
            <Route
                path="/enquiries"
                element={
                    <ProtectedRoute action="view_enquiries">
                        <EnquiryProvider>
                            <Enquiries />
                        </EnquiryProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/quotations"
                element={
                    <ProtectedRoute action="create_quotations">
                        <Quotations />
                    </ProtectedRoute>
                }
            />

            {/* Projects and Subcontract Works */}
            <Route
                path="/projects"
                element={
                    <ProtectedRoute action="view_projects">
                        <Projects />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/projects/:projectId"
                element={
                    <ProtectedRoute action="view_project_details">
                        <ProjectDetails />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/subcontract-works"
                element={
                    <ProtectedRoute action="manage_subcontract_works">
                        <SubcontractWork /> {/* Renamed component for consistency */}
                    </ProtectedRoute>
                }
            />
            <Route
                path="/subcontract-works/:subcontractId"
                element={
                    <ProtectedRoute action="manage_subcontract_works">
                        <SubContractDetails /> {/* Updated naming for clarity */}
                    </ProtectedRoute>
                }
            />

            {/* Assets */}
            <Route
                path="/assets"
                element={
                    <ProtectedRoute action="manage_assets">
                        <AssetProvider> <Assets /></AssetProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/security-deposits"
                element={
                    <ProtectedRoute action="manage_security_deposits">
                        <SecurityDepositsProvider> <SecurityDeposit /></SecurityDepositsProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/EMD"
                element={
                    <ProtectedRoute action="manage_EMD">
                        <EMDProvider> <EMD /></EMDProvider>
                    </ProtectedRoute>
                }
            />

            {/* Accounts */}
            <Route
                path="/accounts"
                element={
                    <ProtectedRoute action="manage_accounts">
                        <AccountsProvider>  <Accounts /></AccountsProvider>
                    </ProtectedRoute>
                }
            />

            {/* Purchases and Stock Management */}
            <Route
                path="/purchases"
                element={
                    <ProtectedRoute action="view_purchase_requests">
                        <Purchases />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/stock-management"
                element={
                    <ProtectedRoute action="manage_stock">
                        <StockManagements />
                    </ProtectedRoute>
                }
            />

            {/* Reports */}
            <Route
                path="/reports"
                element={
                    <ProtectedRoute action="view_reports">
                        <Reports />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/attendance-report"
                element={
                    <ProtectedRoute action="view_attendance_reports">
                        <AttendanceReport />
                    </ProtectedRoute>
                }
            />

            {/* Expenses and Labour Management */}
            <Route
                path="/expenses"
                element={
                    <ProtectedRoute action="log_expenses">
                        <ExpenseBillsProvider><Expenses /></ExpenseBillsProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/labour-management"
                element={
                    <ProtectedRoute action="manage_labour">
                        <LabourManagement />
                    </ProtectedRoute>
                }
            />

            {/* Vehicle Management, HR, Tools Management */}
            <Route
                path="/vehicle-management"
                element={
                    <ProtectedRoute action="manage_vehicle">
                        <VehicleManagement />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/hr"
                element={
                    <ProtectedRoute action="manage_hr">
                        <HR />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/employees"
                element={
                    <ProtectedRoute action="manage_employees">
                        <Employees />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/tools-management"
                element={
                    <ProtectedRoute action="manage_tools">
                        <ToolsManagement />
                    </ProtectedRoute>
                }
            />

            {/* Travel Allowance */}
            <Route
                path="/travel-allowance"
                element={
                    <ProtectedRoute action="approve_travel_allowance">
                        <TravelAllowance />
                    </ProtectedRoute>
                }
            />

            {/* Other Modules */}
            <Route
                path="/reminders"
                element={
                    <ProtectedRoute action="set_reminders">
                        <Reminders />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/project-documents"
                element={
                    <ProtectedRoute action="manage_project_documents">
                        <ProjectDocuments />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/daily-tasks"
                element={
                    <ProtectedRoute action="track_daily_tasks">
                        <DailyTasks />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/daily-notes"
                element={
                    <ProtectedRoute action="write_daily_notes">
                        <DailyNotesProvider>  <DailyNotes /></DailyNotesProvider>
                    </ProtectedRoute>
                }
            />

            {/* Data Management */}
            <Route
                path="/clients"
                element={
                    <ProtectedRoute action="manage_clients">
                        <ClientsProvider> <Clients /></ClientsProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/users"
                element={
                    <ProtectedRoute action="manage_users">
                        <Clients />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/sales-customers"
                element={
                    <ProtectedRoute action="manage_sales_customers">
                        <SalesCustomersProvider><SalesCustomers /></SalesCustomersProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/services"
                element={
                    <ProtectedRoute action="define_services">
                        <ServicesProvider>
                            <ServiceTypesProvider>
                                <Services />
                            </ServiceTypesProvider>
                        </ServicesProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/suppliers"
                element={
                    <ProtectedRoute action="manage_suppliers">
                        <SuppliersProvider><Suppliers /></SuppliersProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/materials"
                element={
                    <ProtectedRoute action="manage_materials">
                        <WorkCategoriesProvider><ProjectSectionsProvider><MaterialProvider> <Materials /></MaterialProvider></ProjectSectionsProvider></WorkCategoriesProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/bank-details"
                element={
                    <ProtectedRoute action="manage_bank_accounts">
                        <BankDetailsProvider><BankDetails /></BankDetailsProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/work-types"
                element={
                    <ProtectedRoute action="define_work_types">
                        <WorkTypesProvider> <WorkTypes /></WorkTypesProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/work-categories"
                element={
                    <ProtectedRoute action="define_work_categories">
                        <WorkCategoriesProvider> <WorkCategories /></WorkCategoriesProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/project-sections"
                element={
                    <ProtectedRoute action="define_project_sections">
                        <ProjectSectionsProvider><ProjectSections /></ProjectSectionsProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/holidays"
                element={
                    <ProtectedRoute action="define_holidays">
                        <HolidaysProvider> <Holidays /></HolidaysProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/work-locations"
                element={
                    <ProtectedRoute action="define_work_locations">
                        <WorkLocationsProvider> <WorkLocations /></WorkLocationsProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/tax-types"
                element={
                    <ProtectedRoute action="define_tax_types">
                        <TaxTypesProvider><TaxTypes /></TaxTypesProvider>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default AdminRoutes;
