import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const DailyNotesContext = createContext();

const DailyNotesProvider = ({ children }) => {
  const [notes, setNotes] = useState([]);

  // Fetch all notes
  const fetchNotes = async () => {
    try {
      const response = await api.get("/daily-notes");
      setNotes(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  // Add a new note
  const addNote = async (note) => {
    try {
      const response = await api.post("/daily-notes", note);
      setNotes((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };

  // Edit an existing note
  const editNote = async (note) => {
    try {
      const response = await api.put(`/daily-notes/${note.id}`, note);
      setNotes((prev) =>
        prev.map((item) => (item.id === note.id ? response.data : item))
      );
    } catch (error) {
      console.error("Error editing note:", error);
    }
  };

  // Delete a note
  const deleteNote = async (id) => {
    try {
      await api.delete(`/daily-notes/${id}`);
      setNotes((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <DailyNotesContext.Provider
      value={{
        notes,
        addNote,
        editNote,
        deleteNote,
      }}
    >
      {children}
    </DailyNotesContext.Provider>
  );
};

export default DailyNotesProvider;
