import React, { useContext, useState } from "react";
import { ProjectSectionsContext } from "../../context/Admin/ProjectSectionsContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import SectionForm from "../../components/forms/SectionForm";

const ProjectSections = () => {
  const { sections, addSection, editSection, deleteSection } =
    useContext(ProjectSectionsContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Section Name", accessor: "sectionName" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            title="Edit Section"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            title="Delete Section"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddClick = () => {
    setSelectedSection(null);
    setIsModalOpen(true);
  };

  const handleEdit = (section) => {
    setSelectedSection(section);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      deleteSection(id);
    }
  };

  const handleFormSubmit = (section) => {
    if (selectedSection) {
      editSection({ ...selectedSection, ...section });
    } else {
      addSection(section);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="p-6 bg-white rounded-xl shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Project Sections</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 shadow-md"
        >
          Add New Section
        </button>
      </div>
      <ReusableTable columns={columns} data={sections} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={selectedSection ? "Edit Section" : "Add New Section"}
        >
          <SectionForm
            onSubmit={handleFormSubmit}
            onCancel={() => setIsModalOpen(false)}
            initialValues={selectedSection || { sectionName: "" }}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProjectSections;
