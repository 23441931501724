import React, { useState } from "react";

const SalesCustomersForm = ({ onSubmit, onCancel, initialValues, serverErrors }) => {
  const [formData, setFormData] = useState(
    initialValues || {
      customerName: "",
      contactPerson: "",
      phoneNumber: "",
      email: "",
      address: "",
      taxNo: "",
      stateCode: "",
    }
  );

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear error on change
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.customerName) newErrors.customerName = "Customer/Company Name is required.";
    if (!formData.contactPerson) newErrors.contactPerson = "Contact person is required.";
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be 10 digits.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address.";
    }
    if (!formData.address) newErrors.address = "Address is required.";
    if (!formData.taxNo) newErrors.taxNo = "Tax number is required.";
    if (!formData.stateCode) {
      newErrors.stateCode = "State code is required.";
    } else if (formData.stateCode.length > 10) {
      newErrors.stateCode = "State code must not exceed 10 characters.";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    onSubmit(formData);
  };

  // Merge server errors into the form errors
  const mergedErrors = { ...errors, ...serverErrors };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium">Customer/Company Name</label>
          <input
            type="text"
            name="customerName"
            value={formData.customerName}
            onChange={handleInputChange}
            className={`w-full border rounded p-2 ${
              mergedErrors.customerName ? "border-red-500" : "border-gray-300"
            }`}
          />
          {mergedErrors.customerName && (
            <p className="text-red-500 text-sm">{mergedErrors.customerName}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium">Contact Person</label>
          <input
            type="text"
            name="contactPerson"
            value={formData.contactPerson}
            onChange={handleInputChange}
            className={`w-full border rounded p-2 ${
              mergedErrors.contactPerson ? "border-red-500" : "border-gray-300"
            }`}
          />
          {mergedErrors.contactPerson && (
            <p className="text-red-500 text-sm">{mergedErrors.contactPerson}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium">Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            className={`w-full border rounded p-2 ${
              mergedErrors.phoneNumber ? "border-red-500" : "border-gray-300"
            }`}
          />
          {mergedErrors.phoneNumber && (
            <p className="text-red-500 text-sm">{mergedErrors.phoneNumber}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className={`w-full border rounded p-2 ${
              mergedErrors.email ? "border-red-500" : "border-gray-300"
            }`}
          />
          {mergedErrors.email && <p className="text-red-500 text-sm">{mergedErrors.email}</p>}
        </div>

        <div>
          <label className="block text-sm font-medium">Address</label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            className={`w-full border rounded p-2 ${
              mergedErrors.address ? "border-red-500" : "border-gray-300"
            }`}
          ></textarea>
          {mergedErrors.address && <p className="text-red-500 text-sm">{mergedErrors.address}</p>}
        </div>

        <div>
          <label className="block text-sm font-medium">Tax No</label>
          <input
            type="text"
            name="taxNo"
            value={formData.taxNo}
            onChange={handleInputChange}
            className={`w-full border rounded p-2 ${
              mergedErrors.taxNo ? "border-red-500" : "border-gray-300"
            }`}
          />
          {mergedErrors.taxNo && <p className="text-red-500 text-sm">{mergedErrors.taxNo}</p>}
        </div>

        <div>
          <label className="block text-sm font-medium">State Code</label>
          <input
            type="text"
            name="stateCode"
            value={formData.stateCode}
            onChange={handleInputChange}
            className={`w-full border rounded p-2 ${
              mergedErrors.stateCode ? "border-red-500" : "border-gray-300"
            }`}
          />
          {mergedErrors.stateCode && (
            <p className="text-red-500 text-sm">{mergedErrors.stateCode}</p>
          )}
        </div>
      </div>

      <div className="flex justify-end space-x-4 mt-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default SalesCustomersForm;
