import React, { useContext, useState } from "react";
import { SalesCustomersContext } from "../../context/Admin/SalesCustomersContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import SalesCustomersForm from "../../components/forms/SalesCustomersForm";

const SalesCustomers = () => {
  const { customers, addCustomer, editCustomer, deleteCustomer } =
    useContext(SalesCustomersContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const columns = [
    { Header: "Sl No", accessor: (_, index) => index + 1 },
    { Header: "Customer/Company Name", accessor: "customerName" },
    { Header: "Contact Person", accessor: "contactPerson" },
    { Header: "Phone", accessor: "phoneNumber" },
    { Header: "Email", accessor: "email" },
    { Header: "Address", accessor: "address" },
    { Header: "Tax No", accessor: "taxNo" },
    { Header: "State Code", accessor: "stateCode" },
    { Header: "Created On", accessor: "createdOn" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedCustomer(null);
    setIsModalOpen(true);
  };

  const handleEdit = (customer) => {
    setModalMode("Edit");
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      deleteCustomer(id);
    }
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      addCustomer(formData);
    } else if (modalMode === "Edit") {
      editCustomer({ ...selectedCustomer, ...formData });
    }
    setIsModalOpen(false);
    setSelectedCustomer(null);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Sales Customers</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Customer
        </button>
      </div>

      <ReusableTable columns={columns} data={customers} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add New Customer" : "Edit Customer"}
        width="w-1/2"
      >
        <SalesCustomersForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          initialValues={selectedCustomer || {}}
        />
      </Modal>
    </div>
  );
};

export default SalesCustomers;
