import React, { useContext, useState } from "react";
import { MaterialContext } from "../../context/Admin/MaterialContext";
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import MaterialForm from "../../components/forms/MaterialForm"; // Form for Materials

const Materials = () => {
  const { materials, addMaterial, editMaterial, deleteMaterial } =
    useContext(MaterialContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedMaterial, setSelectedMaterial] = useState(null); // For editing

  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      addMaterial(formData);
    } else {
      editMaterial({ ...selectedMaterial, ...formData });
    }
    closeModal();
  };

  const openModal = (mode, material = null) => {
    setModalMode(mode);
    setSelectedMaterial(material);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMaterial(null);
  };

  const columns = [
    { Header: "Material Id", accessor: "id" },
    { Header: "Material Name", accessor: "name" },
    { Header: "Section", accessor: "section" },
    { Header: "Specification", accessor: "specification" },
    { Header: "Item Code", accessor: "itemCode" },
    { Header: "Category", accessor: "category" },
    { Header: "Unit", accessor: "unit" },
    { Header: "Unit Price (Purchase)", accessor: "unitPrice" },
    { Header: "GST %", accessor: "gst" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => openModal("Edit", row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded"
            onClick={() => deleteMaterial(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Materials</h1>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          onClick={() => openModal("Add")}
        >
          Add New Material
        </button>
      </div>
      <ReusableTable columns={columns} data={materials} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add New Material" : "Edit Material"}
        >
          <MaterialForm
            onSubmit={handleAddEditSubmit}
            onCancel={closeModal}
            initialValues={
              selectedMaterial || {
                name: "",
                section: "",
                specification: "",
                itemCode: "",
                category: "",
                unit: "",
                unitPrice: "",
                gst: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default Materials;
