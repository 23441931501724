import React, { useState, useMemo } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import EnquiryForm from "../../components/forms/EnquiryForm";
import AssignEnquiryForm from "../../components/forms/AssignEnquiryForm";
import { useEnquiries } from "../../context/Admin/EnquiryContext";

const Enquiries = () => {
  const {
    enquiries,
    isLoading,
    createEnquiry,
    updateEnquiry,
    deleteEnquiry,
    assignEnquiry,
  } = useEnquiries();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "slNo" },
      { Header: "ID", accessor: "id" },
      { Header: "Enq Ref No", accessor: "enqRefNo" },
      { Header: "Enquiry Status", accessor: "enquiryStatus" },
      { Header: "Assigned To", accessor: "assignedTo" },
      { Header: "Customer", accessor: "customer" },
      { Header: "Remarks", accessor: "remarks" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-green-500 text-white px-3 py-1 rounded"
              onClick={() => handleAssign(row.original)}
            >
              Assign Enquiry
            </button>
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Update
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => deleteEnquiry(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedEnquiry(null);
    setIsModalOpen(true);
  };

  const handleEdit = (enquiry) => {
    setModalMode("Update");
    setSelectedEnquiry(enquiry);
    setIsModalOpen(true);
  };

  const handleAssign = (enquiry) => {
    setModalMode("Assign");
    setSelectedEnquiry(enquiry);
    setIsModalOpen(true);
  };

  const handleFormSubmit = (data) => {
    if (modalMode === "Add") createEnquiry(data);
    if (modalMode === "Update") updateEnquiry(selectedEnquiry.id, data);
    if (modalMode === "Assign") assignEnquiry(selectedEnquiry.id, data);

    setIsModalOpen(false);
  };

  const getModalTitle = () => {
    switch (modalMode) {
      case "Add":
        return "Create Enquiry";
      case "Update":
        return "Update Enquiry";
      case "Assign":
        return "Assign Enquiry";
      default:
        return "";
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Enquiries</h2>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
          onClick={handleAdd}
        >
          Create Enquiry
        </button>
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <ReusableTable columns={columns} data={enquiries} />
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={getModalTitle()}
        width="w-1/2"
      >
        {modalMode === "Assign" ? (
          <AssignEnquiryForm
            onSubmit={handleFormSubmit}
            onCancel={() => setIsModalOpen(false)}
          />
        ) : (
          <EnquiryForm
            initialValues={selectedEnquiry}
            mode={modalMode}
            onSubmit={handleFormSubmit}
            onCancel={() => setIsModalOpen(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default Enquiries;