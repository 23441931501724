import React, { useState, useEffect } from "react";

const EnquiryForm = ({ onSubmit, onCancel, initialValues = {}, mode = "Add" }) => {
  const defaultValues = {
    enquiryDate: "",
    customer: "",
    requestedPerson: "",
    mobileNo: "",
    contactPerson: "",
    contactMobileNo: "",
    requiredService: "",
    serviceType: "",
    referenceNo: "",
    workPlace: "",
    enquiryMode: "By Call",
    priority: "High",
    subject: "",
    serviceDetails: "",
    observations: "",
    actionsTaken: "",
    remarks: "",
    enquiryStatus: "Open",
    assignTo: "",
  };

  const [formData, setFormData] = useState(defaultValues);

  // Populate formData when editing
  useEffect(() => {
    setFormData({ ...defaultValues, ...initialValues });
  }, [initialValues]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(formData); // Pass form data to the parent component
  };

  return (
    <div className="space-y-4">
      {/* Section 1: Basic Details */}
      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-xs font-medium">Enquiry Date</label>
          <input
            type="date"
            name="enquiryDate"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.enquiryDate}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="block text-xs font-medium">Customer/Company</label>
          <input
            type="text"
            name="customer"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            placeholder="Select Customer"
            value={formData.customer}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-xs font-medium">Requested Person</label>
          <input
            type="text"
            name="requestedPerson"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.requestedPerson}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="block text-xs font-medium">Mobile No</label>
          <input
            type="text"
            name="mobileNo"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.mobileNo}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-xs font-medium">Contact Person</label>
          <input
            type="text"
            name="contactPerson"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.contactPerson}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="block text-xs font-medium">Contact Mobile No</label>
          <input
            type="text"
            name="contactMobileNo"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.contactMobileNo}
            onChange={handleInputChange}
          />
        </div>
      </div>

      {/* Section 2: Service Details */}
      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-xs font-medium">Required Service</label>
          <input
            type="text"
            name="requiredService"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.requiredService}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="block text-xs font-medium">Service Type</label>
          <input
            type="text"
            name="serviceType"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.serviceType}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-3">
        <div>
          <label className="block text-xs font-medium">Reference No</label>
          <input
            type="text"
            name="referenceNo"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.referenceNo}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="block text-xs font-medium">Work Place</label>
          <input
            type="text"
            name="workPlace"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.workPlace}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="block text-xs font-medium">Enquiry Mode</label>
          <select
            name="enquiryMode"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.enquiryMode}
            onChange={handleInputChange}
          >
            <option>By Call</option>
            <option>By Email</option>
            <option>In Person</option>
          </select>
        </div>
      </div>

      {/* Section 3: Priority, Subject, and Status */}
      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-xs font-medium">Priority</label>
          <select
            name="priority"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.priority}
            onChange={handleInputChange}
          >
            <option>High</option>
            <option>Medium</option>
            <option>Low</option>
          </select>
        </div>
        <div>
          <label className="block text-xs font-medium">Subject/Nature of Enquiry</label>
          <input
            type="text"
            name="subject"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.subject}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-xs font-medium">Enquiry Status</label>
          <select
            name="enquiryStatus"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.enquiryStatus}
            onChange={handleInputChange}
          >
            <option>Open</option>
            <option>Quote Pending</option>
            <option>Waiting for Approval</option>
            <option>Work Under Progress</option>
            <option>Closed</option>
            <option>Cancelled</option>
          </select>
        </div>
        <div>
          <label className="block text-xs font-medium">Assign To</label>
          <input
            type="text"
            name="assignTo"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            placeholder="Select User"
            value={formData.assignTo}
            onChange={handleInputChange}
          />
        </div>
      </div>

      {/* Section 4: Additional Fields */}
      <div className="grid grid-cols-1 gap-3">
        <div>
          <label className="block text-xs font-medium">Remarks</label>
          <textarea
            name="remarks"
            className="w-full mt-1 border border-gray-300 rounded p-1 text-sm"
            value={formData.remarks}
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>

      {/* Submit and Cancel Buttons */}
      <div className="flex justify-end space-x-4 mt-6">
        <button
          className="bg-gray-300 text-black px-4 py-2 rounded shadow hover:bg-gray-400"
          onClick={onCancel}
        >
          Close
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
          onClick={handleSubmit}
        >
          {mode === "Add" ? "Add" : "Update"}
        </button>
      </div>
    </div>
  );
};

export default EnquiryForm;
