import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Import Axios instance

export const SecurityDepositsContext = createContext();

const SecurityDepositsProvider = ({ children }) => {
  const [deposits, setDeposits] = useState([]);

  // Fetch all security deposits
  const fetchDeposits = async () => {
    try {
      const response = await api.get("/security-deposits");
      setDeposits(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Failed to fetch security deposits:", error);
    }
  };

  // Add a new security deposit
  const addDeposit = async (depositDetails) => {
    try {
      const response = await api.post("/security-deposits", depositDetails);
      setDeposits([...deposits, response.data]);
    } catch (error) {
      console.error("Failed to add security deposit:", error);
    }
  };

  // Edit an existing security deposit
  const editDeposit = async (depositDetails) => {
    try {
      const response = await api.put(`/security-deposits/${depositDetails.id}`, depositDetails);
      setDeposits(
        deposits.map((item) =>
          item.id === depositDetails.id ? response.data : item
        )
      );
    } catch (error) {
      console.error("Failed to edit security deposit:", error);
    }
  };

  // Delete a security deposit
  const deleteDeposit = async (id) => {
    try {
      await api.delete(`/security-deposits/${id}`);
      setDeposits((prevDeposits) => prevDeposits.filter((deposit) => deposit.id !== id));
    } catch (error) {
      console.error("Failed to delete deposit:", error);
    }
  };
  

  useEffect(() => {
    fetchDeposits();
  }, []);

  return (
    <SecurityDepositsContext.Provider
      value={{ deposits, addDeposit, editDeposit, deleteDeposit }}
    >
      {children}
    </SecurityDepositsContext.Provider>
  );
};

export default SecurityDepositsProvider;
