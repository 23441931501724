import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const AccountsContext = createContext();

const AccountsProvider = ({ children }) => {
  const [accounts, setAccounts] = useState([]);

  // Fetch all accounts
  const fetchAccounts = async () => {
    try {
      const response = await api.get("/accounts");
      setAccounts(response.data || []); // Ensure data is an array
    } catch (error) {
      console.error("Failed to fetch accounts:", error);
    }
  };

  // Add a new account
  const addAccount = async (account) => {
    try {
      const response = await api.post("/accounts", account);
      setAccounts((prevAccounts) => [...prevAccounts, response.data]);
    } catch (error) {
      console.error("Failed to add account:", error);
    }
  };

  // Edit an existing account
  const editAccount = async (account) => {
    try {
      const response = await api.put(`/accounts/${account.id}`, account);
      setAccounts((prevAccounts) =>
        prevAccounts.map((acc) => (acc.id === account.id ? response.data : acc))
      );
    } catch (error) {
      console.error("Failed to edit account:", error);
    }
  };

  // Delete an account
  const deleteAccount = async (id) => {
    try {
      await api.delete(`/accounts/${id}`);
      setAccounts((prevAccounts) => prevAccounts.filter((acc) => acc.id !== id));
    } catch (error) {
      console.error("Failed to delete account:", error);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <AccountsContext.Provider
      value={{ accounts, addAccount, editAccount, deleteAccount }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

export default AccountsProvider;
