import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const ServicesContext = createContext();

const ServicesProvider = ({ children }) => {
  const [services, setServices] = useState([]);

  const fetchServices = async () => {
    try {
      const response = await api.get("/services");
      const normalizedData = response.data.map((service) => ({
        ...service,
        isActive: Boolean(service.is_active), // Convert is_active to a boolean
      }));
      setServices(normalizedData);
    } catch (error) {
      console.error("Failed to fetch services:", error);
    }
  };
  

  const addService = async (service) => {
    try {
      const response = await api.post("/services", service);
      setServices((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add service:", error);
    }
  };

  const editService = async (service) => {
    try {
      const payload = {
        ...service,
        is_active: service.isActive ? 1 : 0, // Convert isActive to is_active
      };
      const response = await api.put(`/services/${service.id}`, payload);
      setServices((prev) =>
        prev.map((item) =>
          item.id === service.id
            ? { ...response.data, isActive: Boolean(response.data.is_active) }
            : item
        )
      );
    } catch (error) {
      console.error("Failed to edit service:", error);
    }
  };
  

  const deleteService = async (id) => {
    try {
      await api.delete(`/services/${id}`);
      setServices((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete service:", error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <ServicesContext.Provider
      value={{
        services,
        addService,
        editService,
        deleteService,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export default ServicesProvider;
