import React, { useContext, useState, useMemo } from "react";
import { ServicesContext } from "../../context/Admin/ServicesContext";
import { ServiceTypesContext } from "../../context/Admin/ServiceTypesContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import ServiceForm from "../../components/forms/ServiceForm";
import ServiceTypeForm from "../../components/forms/ServiceTypeForm";

const Services = () => {
  const { services, addService, editService, deleteService } =
    useContext(ServicesContext);
  const { serviceTypes, addServiceType, editServiceType, deleteServiceType } =
    useContext(ServiceTypesContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isServiceTypeModal, setIsServiceTypeModal] = useState(false);

  const serviceColumns = useMemo(
    () => [
      { Header: "Sl No", accessor: (_row, i) => i + 1 },
      { Header: "Service Name", accessor: "name" },
      {
        Header: "Active Status",
        accessor: "isActive",
        Cell: ({ value }) => (
          <span
            className={`px-3 py-1 rounded font-semibold text-xs ${
              value ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"
            }`}
          >
            {value ? "Active" : "Inactive"}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value, row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
              onClick={() => openModal("Edit", row.original, false)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              onClick={() => handleDelete(value, false)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const serviceTypeColumns = useMemo(
    () => [
      { Header: "Sl No", accessor: (_row, i) => i + 1 },
      { Header: "Service Type Name", accessor: "name" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value, row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
              onClick={() => openModal("Edit", row.original, true)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              onClick={() => handleDelete(value, true)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      if (isServiceTypeModal) {
        addServiceType(formData);
      } else {
        addService(formData);
      }
    } else {
      if (isServiceTypeModal) {
        editServiceType({ ...selectedItem, ...formData });
      } else {
        editService({ ...selectedItem, ...formData });
      }
    }
    closeModal();
  };

  const handleDelete = (id, isType) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      if (isType) {
        deleteServiceType(id);
      } else {
        deleteService(id);
      }
    }
  };

  const openModal = (mode, item = null, isTypeModal = false) => {
    setModalMode(mode);
    setSelectedItem(item);
    setIsModalOpen(true);
    setIsServiceTypeModal(isTypeModal);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <div className="p-8">
      {/* Services Section */}
      <div className="mb-12 bg-white rounded-xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-700">
            List of Services
          </h2>
          <button
            className="bg-green-500 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-green-600"
            onClick={() => openModal("Add", null, false)}
          >
            Add Service
          </button>
        </div>
        <ReusableTable columns={serviceColumns} data={services} />
      </div>

      {/* Service Types Section */}
      <div className="mb-12 bg-white rounded-xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-700">
            List of Service Types
          </h2>
          <button
            className="bg-green-500 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-green-600"
            onClick={() => openModal("Add", null, true)}
          >
            Add Service Type
          </button>
        </div>
        <ReusableTable columns={serviceTypeColumns} data={serviceTypes} />
      </div>

      {/* Modal for Add/Edit */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add New Item" : "Edit Item"}
          width="w-1/2"
        >
          {isServiceTypeModal ? (
            <ServiceTypeForm
              onSubmit={handleAddEditSubmit}
              onCancel={closeModal}
              initialValues={selectedItem || { name: "" }}
            />
          ) : (
            <ServiceForm
              onSubmit={handleAddEditSubmit}
              onCancel={closeModal}
              initialValues={selectedItem || { name: "", isActive: true }}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default Services;
