import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const ProjectSectionsContext = createContext();

const ProjectSectionsProvider = ({ children }) => {
  const [sections, setSections] = useState([]);

  // Fetch all sections
  const fetchSections = async () => {
    try {
      const response = await api.get("/sections");
      setSections(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
  };

  // Add a new section
  const addSection = async (section) => {
    try {
      const response = await api.post("/sections", section);
      setSections((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add section:", error);
    }
  };

  // Edit an existing section
  const editSection = async (section) => {
    try {
      const response = await api.put(`/sections/${section.id}`, section);
      setSections((prev) =>
        prev.map((item) => (item.id === section.id ? response.data : item))
      );
    } catch (error) {
      console.error("Failed to edit section:", error);
    }
  };

  // Delete a section
  const deleteSection = async (id) => {
    try {
      await api.delete(`/sections/${id}`);
      setSections((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete section:", error);
    }
  };

  useEffect(() => {
    fetchSections();
  }, []);

  return (
    <ProjectSectionsContext.Provider
      value={{
        sections,
        addSection,
        editSection,
        deleteSection,
      }}
    >
      {children}
    </ProjectSectionsContext.Provider>
  );
};

export default ProjectSectionsProvider;
