import React, { useContext, useState } from "react";
import { BankDetailsContext } from "../../context/Admin/BankDetailsContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import BankForm from "../../components/forms/BankForm";

const BankDetails = () => {
  const { bankDetails, addBankDetail, editBankDetail, deleteBankDetail } =
    useContext(BankDetailsContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);

  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "A/C Holder Company Name", accessor: "accountHolder" },
    { Header: "Bank Name", accessor: "bankName" },
    { Header: "Account No", accessor: "accountNumber" },
    { Header: "IFSC", accessor: "ifscCode" },
    { Header: "Bank Branch", accessor: "branch" },
    { Header: "Swift Code", accessor: "swiftCode" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddClick = () => {
    setSelectedBank(null);
    setIsModalOpen(true);
  };

  const handleEdit = (bank) => {
    setSelectedBank(bank);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this bank detail?")) {
      deleteBankDetail(id);
    }
  };

  const handleFormSubmit = (bank) => {
    if (selectedBank) {
      editBankDetail({ ...selectedBank, ...bank });
    } else {
      addBankDetail(bank);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Bank Details</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add New Bank Detail
        </button>
      </div>
      <ReusableTable columns={columns} data={bankDetails} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={selectedBank ? "Edit Bank Detail" : "Add New Bank Detail"}
        >
          <BankForm
            onSubmit={handleFormSubmit}
            onCancel={() => setIsModalOpen(false)}
            initialValues={
              selectedBank || {
                accountHolder: "",
                bankName: "",
                accountNumber: "",
                ifscCode: "",
                branch: "",
                swiftCode: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default BankDetails;
