import React, { useContext, useState } from "react";
import { ClientsContext } from "../../context/Admin/ClientsContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import ClientForm from "../../components/forms/ClientForm";

const Clients = () => {
  const { clients, addClient, editClient, deleteClient } = useContext(ClientsContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [selectedClient, setSelectedClient] = useState(null);

  const columns = [
    { Header: "Client ID", accessor: "id" },
    { Header: "Name", accessor: "clientName" },
    { Header: "Contact Person", accessor: "contactPerson" },
    { Header: "Phone", accessor: "phone" },
    { Header: "Whatsapp No", accessor: "whatsappNo" },
    { Header: "Email", accessor: "email" },
    { Header: "PO Box", accessor: "poBox" },
    { Header: "Address", accessor: "address" },
    { Header: "Company Name", accessor: "companyName" },
    { Header: "Group Name", accessor: "groupName" },
    { Header: "GST/TRN No", accessor: "gstTrnNo" },
    { Header: "GST Date", accessor: "gstDate" },
    { Header: "GST State", accessor: "stateCode" },
    { Header: "Civil ID", accessor: "civilIdNo" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedClient(null);
    setIsModalOpen(true);
  };

  const handleEdit = (client) => {
    setModalMode("Edit");
    setSelectedClient(client);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this client?")) {
      deleteClient(id);
    }
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      addClient(formData);
    } else if (modalMode === "Edit") {
      editClient({ ...selectedClient, ...formData });
    }
    setIsModalOpen(false);
    setSelectedClient(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Clients</h1>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Client
        </button>
      </div>

      <ReusableTable columns={columns} data={clients} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add New Client" : "Edit Client"}
        width="w-3/4"
      >
        <ClientForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          initialValues={selectedClient || {}}
        />
      </Modal>
    </div>
  );
};

export default Clients;
