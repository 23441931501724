import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const WorkLocationsContext = createContext();

const WorkLocationsProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);

  // Fetch all work locations
  const fetchLocations = async () => {
    try {
      const response = await api.get("/work-locations");
      setLocations(response.data || []); // Ensure response is set to an array
    } catch (error) {
      console.error("Failed to fetch work locations:", error);
    }
  };

  // Add a new work location
  const addLocation = async (location) => {
    try {
      const response = await api.post("/work-locations", location);
      setLocations((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add work location:", error);
    }
  };

  // Edit an existing work location
  const editLocation = async (location) => {
    try {
      const response = await api.put(`/work-locations/${location.id}`, location);
      setLocations((prev) =>
        prev.map((item) => (item.id === location.id ? response.data : item))
      );
    } catch (error) {
      console.error("Failed to edit work location:", error);
    }
  };

  // Delete a work location
  const deleteLocation = async (id) => {
    try {
      await api.delete(`/work-locations/${id}`);
      setLocations((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete work location:", error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <WorkLocationsContext.Provider
      value={{
        locations,
        addLocation,
        editLocation,
        deleteLocation,
      }}
    >
      {children}
    </WorkLocationsContext.Provider>
  );
};

export default WorkLocationsProvider;
