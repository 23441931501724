import React, { useContext, useMemo, useState } from "react";
import { SuppliersContext } from "../../context/Admin/SuppliersContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import SupplierForm from "../../components/forms/SupplierForm";

const Suppliers = () => {
  const { suppliers, addSupplier, editSupplier, deleteSupplier } =
    useContext(SuppliersContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const columns = useMemo(
    () => [
      { Header: "Supplier ID", accessor: "id" }, // Use the unique id here
      { Header: "Supplier Name", accessor: "supplierName" },
      { Header: "Vendor No", accessor: "vendorNo" },
      { Header: "Supplier Address", accessor: "supplierAddress" },
      { Header: "Category", accessor: "category" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Email", accessor: "email" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "GST No", accessor: "gstNo" },
      { Header: "GST Date", accessor: "gstDate" },
      { Header: "PAN No", accessor: "panNo" },
      { Header: "Aadhar", accessor: "aadharNo" },
      { Header: "Bank A/C No", accessor: "bankAccountNo" },
      { Header: "IFSC Code", accessor: "ifscCode" },
      { Header: "Bank Name", accessor: "bankName" },
      { Header: "Branch", accessor: "branchName" },
      { Header: "Opening Balance", accessor: "openingBalance" },
      { Header: "Total Bill Amount", accessor: "totalBillAmount" },
      { Header: "Total Paid", accessor: "totalPaid" },
      { Header: "Current Balance", accessor: "currentBalance" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)} // Use id for deletion
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedSupplier(null);
    setIsModalOpen(true);
  };

  const handleEdit = (supplier) => {
    setModalMode("Edit");
    setSelectedSupplier(supplier);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this supplier?")) {
      deleteSupplier(id); // Pass id for deletion
    }
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      addSupplier(formData);
    } else if (modalMode === "Edit") {
      editSupplier({ ...selectedSupplier, ...formData, id: selectedSupplier.id }); // Ensure id is preserved
    }
    setIsModalOpen(false);
    setSelectedSupplier(null);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Suppliers</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Supplier
        </button>
      </div>

      <ReusableTable columns={columns} data={suppliers} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add New Supplier" : "Edit Supplier"}
        width="w-3/4"
      >
        <SupplierForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          initialValues={selectedSupplier}
        />
      </Modal>
    </div>
  );
};

export default Suppliers;
