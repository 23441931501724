import React, { useState } from "react";
import { FiTrash } from "react-icons/fi";

const QuotationForm = ({ onSubmit, onCancel, initialValues = {} }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    customerId: "",
    customerName: "",
    location: "",
    phone: "",
    contactPerson: "",
    email: "",
    quotationDate: "",
    expiryDate: "",
    subject: "",
    quotationDescription: "",
    paymentTerms: [{ stage: "", payment: "", items: "", amount: "", remarks: "" }],
    termsAndConditions: [{ title: "", description: "" }],
    ...initialValues,
  });

  const handleInputChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleNestedChange = (index, key, value, section) =>
    setFormData((prev) => ({
      ...prev,
      [section]: prev[section].map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      ),
    }));

  const addRow = (section, defaultRow) =>
    setFormData({
      ...formData,
      [section]: [...formData[section], defaultRow],
    });

  const removeRow = (section, index) =>
    setFormData({
      ...formData,
      [section]: formData[section].filter((_, i) => i !== index),
    });

  const handleNextStep = () => {
    if (currentStep === 1 && (!formData.customerName || !formData.quotationDate || !formData.subject)) {
      alert("Please fill in the required fields!");
    } else {
      setCurrentStep(2);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const stepOneFields = [
    { name: "customerId", placeholder: "Customer ID" },
    { name: "customerName", placeholder: "Customer Name", required: true },
    { name: "location", placeholder: "Location" },
    { name: "phone", placeholder: "Phone" },
    { name: "contactPerson", placeholder: "Contact Person" },
    { name: "email", placeholder: "Email", type: "email" },
    { name: "quotationDate", placeholder: "Quotation Date", type: "date", required: true },
    { name: "expiryDate", placeholder: "Expiry Date", type: "date" },
    { name: "subject", placeholder: "Subject", required: true },
  ];

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {/* Step Navigation */}
      <div className="flex justify-between border-b pb-3 mb-4">
        {["1. Customer & Quotation Info", "2. Items & Quote Value"].map((step, idx) => (
          <div
            key={idx}
            className={`px-2 py-1 rounded-full text-sm ${
              currentStep === idx + 1 ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-600"
            }`}
          >
            {step}
          </div>
        ))}
      </div>

      {/* Step 1 */}
      {currentStep === 1 && (
        <>
          <div className="space-y-2">
            {stepOneFields.map(({ name, placeholder, type = "text", required }) => (
              <input
                key={name}
                type={type}
                name={name}
                placeholder={placeholder}
                value={formData[name]}
                onChange={handleInputChange}
                required={required}
                className="border border-gray-300 rounded p-2 text-sm w-full"
              />
            ))}
            <textarea
              name="quotationDescription"
              placeholder="Quotation Description"
              value={formData.quotationDescription}
              onChange={handleInputChange}
              className="border border-gray-300 rounded p-2 text-sm w-full"
            />
          </div>

          {/* Payment Terms */}
          <h3 className="text-sm font-bold mt-6 mb-2">Payment Terms</h3>
          {formData.paymentTerms.map((term, idx) => (
            <div
              key={idx}
              className="flex items-center gap-2 mb-2 p-2 border border-gray-200 rounded"
            >
              {["stage", "payment", "items", "amount", "remarks"].map((key) => (
                <input
                  key={key}
                  type="text"
                  placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                  value={term[key]}
                  onChange={(e) => handleNestedChange(idx, key, e.target.value, "paymentTerms")}
                  className="border border-gray-300 rounded p-1 text-xs flex-1"
                />
              ))}
              <button
                type="button"
                onClick={() => removeRow("paymentTerms", idx)}
                className="text-red-500 hover:text-red-600 flex justify-center items-center"
              >
                <FiTrash size={18} />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() =>
              addRow("paymentTerms", { stage: "", payment: "", items: "", amount: "", remarks: "" })
            }
            className="text-blue-500 text-xs hover:underline"
          >
            Add Payment Term
          </button>

          {/* Terms & Conditions */}
          <h3 className="text-sm font-bold mt-6 mb-2">Terms & Conditions</h3>
          {formData.termsAndConditions.map((term, idx) => (
            <div
              key={idx}
              className="flex items-center gap-2 mb-2 p-2 border border-gray-200 rounded"
            >
              {["title", "description"].map((key) => (
                <input
                  key={key}
                  type="text"
                  placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                  value={term[key]}
                  onChange={(e) => handleNestedChange(idx, key, e.target.value, "termsAndConditions")}
                  className="border border-gray-300 rounded p-1 text-xs flex-1"
                />
              ))}
              <button
                type="button"
                onClick={() => removeRow("termsAndConditions", idx)}
                className="text-red-500 hover:text-red-600 flex justify-center items-center"
              >
                <FiTrash size={18} />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addRow("termsAndConditions", { title: "", description: "" })}
            className="text-blue-500 text-xs hover:underline"
          >
            Add Term
          </button>
        </>
      )}

      {/* Step 2 */}
      {currentStep === 2 && <p className="text-gray-500 text-sm">Step 2 is under development.</p>}

      {/* Navigation Buttons */}
      <div className="flex justify-between mt-6">
        {currentStep > 1 && (
          <button
            type="button"
            onClick={() => setCurrentStep(currentStep - 1)}
            className="bg-gray-200 px-2 py-1 rounded text-xs"
          >
            Previous
          </button>
        )}
        {currentStep === 1 && (
          <button
            type="button"
            onClick={handleNextStep}
            className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
          >
            Next
          </button>
        )}
        {currentStep === 2 && (
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={onCancel}
              className="bg-gray-200 px-2 py-1 rounded text-xs"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default QuotationForm;
