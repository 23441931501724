import React, { useState, useEffect } from "react";

const AssetForm = ({ initialValues, onSubmit, onCancel }) => {
  // Default state for "Add" mode
  const defaultFormState = {
    assetName: "",
    description: "",
    quantity: 0,
    unitCost: 0,
    totalCost: 0,
    purchasedOn: "",
  };

  // Form state initialized based on `initialValues` or default values
  const [formDetails, setFormDetails] = useState(initialValues || defaultFormState);

  useEffect(() => {
    // If `initialValues` change, update form state (e.g., Edit mode)
    if (initialValues) {
      setFormDetails(initialValues);
    } else {
      setFormDetails(defaultFormState);
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => {
      const updatedValue =
        name === "quantity" ? parseInt(value, 10) || 0 : value; // Ensure quantity is an integer
  
      return {
        ...prev,
        [name]: updatedValue,
        // Auto-calculate totalCost if quantity or unitCost changes
        totalCost:
          name === "quantity" || name === "unitCost"
            ? name === "quantity"
              ? updatedValue * prev.unitCost
              : prev.quantity * updatedValue
            : prev.totalCost,
      };
    });
  };
  

  const handleSubmit = () => {
    // Ensure quantity is a valid integer
    if (!Number.isInteger(formDetails.quantity) || formDetails.quantity < 0) {
      alert("Quantity must be a valid integer greater than or equal to 0.");
      return;
    }
  
    if (onSubmit) onSubmit(formDetails);
  };
  
  return (
    <div className="space-y-6">
      {/* Form Fields */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium">Asset Name</label>
          <input
            type="text"
            name="assetName"
            value={formDetails.assetName}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
            placeholder="Enter asset name"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Description</label>
          <input
            type="text"
            name="description"
            value={formDetails.description}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
            placeholder="Enter description"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Quantity</label>
          <input
            type="number"
            name="quantity"
            value={formDetails.quantity}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
            placeholder="Enter quantity"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Unit Cost</label>
          <input
            type="number"
            name="unitCost"
            value={formDetails.unitCost}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
            placeholder="Enter unit cost"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Total Cost</label>
          <input
            type="number"
            name="totalCost"
            value={formDetails.totalCost}
            readOnly
            className="w-full border border-gray-300 rounded p-2 bg-gray-100"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Purchased Date</label>
          <input
            type="date"
            name="purchasedOn"
            value={formDetails.purchasedOn}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded p-2"
          />
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-4">
        <button
          onClick={onCancel}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          {initialValues ? "Update" : "Add"} Asset
        </button>
      </div>
    </div>
  );
};

export default AssetForm;
