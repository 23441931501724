import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const MaterialContext = createContext();

const MaterialProvider = ({ children }) => {
  const [materials, setMaterials] = useState([]);

  // Fetch all materials
  const fetchMaterials = async () => {
    try {
      const response = await api.get("/materials");
      setMaterials(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Failed to fetch materials:", error);
    }
  };

  // Add a new material
  const addMaterial = async (material) => {
    try {
      const response = await api.post("/materials", material);
      setMaterials((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add material:", error);
    }
  };

  // Edit an existing material
  const editMaterial = async (material) => {
    try {
      const response = await api.put(`/materials/${material.id}`, material);
      setMaterials((prev) =>
        prev.map((item) => (item.id === material.id ? response.data : item))
      );
    } catch (error) {
      console.error("Failed to edit material:", error);
    }
  };

  // Delete a material
  const deleteMaterial = async (id) => {
    try {
      await api.delete(`/materials/${id}`);
      setMaterials((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete material:", error);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  return (
    <MaterialContext.Provider
      value={{
        materials,
        addMaterial,
        editMaterial,
        deleteMaterial,
      }}
    >
      {children}
    </MaterialContext.Provider>
  );
};

export default MaterialProvider;
