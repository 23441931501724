import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Import the configured Axios instance

export const AssetContext = createContext();

const AssetProvider = ({ children }) => {
  const [assets, setAssets] = useState([]);

  // Fetch all assets
  const fetchAssets = async () => {
    try {
      const response = await api.get("/assets");

      // Access the `data` key inside the response
      const assetsArray = response.data.data || [];

      // No need for transformation as the API already provides the correct structure
      setAssets(assetsArray);
    } catch (error) {
      console.error("Failed to fetch assets:", error);
    }
  };

  // Add a new asset
  const addAsset = async (assetDetails) => {
    try {
      const response = await api.post("/assets", assetDetails);

      // Use the response directly as it matches the expected structure
      const newAsset = response.data;
      setAssets([...assets, newAsset]);
    } catch (error) {
      console.error("Failed to add asset:", error);
    }
  };

  // Edit an existing asset
const editAsset = async (assetDetails) => {
    try {
      const response = await api.put(`/assets/${assetDetails.id}`, assetDetails);
  
      // Use the updated asset from the response
      const updatedAsset = response.data.data;
  
      // Update the assets array in the state
      setAssets((prevAssets) =>
        prevAssets.map((item) =>
          item.id === updatedAsset.id ? updatedAsset : item
        )
      );
    } catch (error) {
      console.error("Failed to edit asset:", error);
    }
  };
  

  // Delete an asset
  const deleteAsset = async (id) => {
    try {
      await api.delete(`/assets/${id}`);
      setAssets(assets.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete asset:", error);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <AssetContext.Provider
      value={{ assets, addAsset, editAsset, deleteAsset }}
    >
      {children}
    </AssetContext.Provider>
  );
};

export default AssetProvider;
