import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Import Axios instance

export const EMDContext = createContext();

const EMDProvider = ({ children }) => {
  const [emdRecords, setEmdRecords] = useState([]);

  // Fetch all EMD records
  const fetchEmdRecords = async () => {
    try {
      const response = await api.get("/emds");
      setEmdRecords(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Failed to fetch EMD records:", error);
    }
  };

  // Add a new EMD record
  const addEmd = async (emdDetails) => {
    try {
      const response = await api.post("/emds", emdDetails);
      setEmdRecords((prevRecords) => [...prevRecords, response.data]);
    } catch (error) {
      console.error("Failed to add EMD record:", error);
    }
  };

  // Edit an existing EMD record
  const editEmd = async (emdDetails) => {
    try {
      const response = await api.put(`/emds/${emdDetails.id}`, emdDetails);
      setEmdRecords((prevRecords) =>
        prevRecords.map((record) =>
          record.id === emdDetails.id ? response.data : record
        )
      );
    } catch (error) {
      console.error("Failed to edit EMD record:", error);
    }
  };

  // Delete an EMD record
  const deleteEmd = async (id) => {
    try {
      await api.delete(`/emds/${id}`);
      setEmdRecords((prevRecords) =>
        prevRecords.filter((record) => record.id !== id)
      );
    } catch (error) {
      console.error("Failed to delete EMD record:", error);
    }
  };

  useEffect(() => {
    fetchEmdRecords();
  }, []);

  return (
    <EMDContext.Provider
      value={{
        emdRecords,
        addEmd,
        editEmd,
        deleteEmd,
      }}
    >
      {children}
    </EMDContext.Provider>
  );
};

export default EMDProvider;
