import axios from "axios";

// Create an Axios instance with a base URL
const api = axios.create({
  baseURL: "https://cadmin.prepstripe.com/api", // Replace with your API's base URL
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
