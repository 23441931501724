import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const ServiceTypesContext = createContext();

const ServiceTypesProvider = ({ children }) => {
  const [serviceTypes, setServiceTypes] = useState([]);

  const fetchServiceTypes = async () => {
    try {
      const response = await api.get("/service-types");
      setServiceTypes(response.data || []);
    } catch (error) {
      console.error("Failed to fetch service types:", error);
    }
  };

  const addServiceType = async (serviceType) => {
    try {
      const response = await api.post("/service-types", serviceType);
      setServiceTypes((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add service type:", error);
    }
  };

  const editServiceType = async (serviceType) => {
    try {
      const response = await api.put(`/service-types/${serviceType.id}`, serviceType);
      setServiceTypes((prev) =>
        prev.map((item) =>
          item.id === serviceType.id ? response.data : item
        )
      );
    } catch (error) {
      console.error("Failed to edit service type:", error);
    }
  };

  const deleteServiceType = async (id) => {
    try {
      await api.delete(`/service-types/${id}`);
      setServiceTypes((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete service type:", error);
    }
  };

  useEffect(() => {
    fetchServiceTypes();
  }, []);

  return (
    <ServiceTypesContext.Provider
      value={{
        serviceTypes,
        addServiceType,
        editServiceType,
        deleteServiceType,
      }}
    >
      {children}
    </ServiceTypesContext.Provider>
  );
};

export default ServiceTypesProvider;
