// context/Admin/SuppliersContext.js
import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const SuppliersContext = createContext();

const SuppliersProvider = ({ children }) => {
  const [suppliers, setSuppliers] = useState([]);

  // Fetch suppliers from API
  const fetchSuppliers = async () => {
    try {
      const response = await api.get("/suppliers");
      setSuppliers(response.data || []);
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
    }
  };

  // Add a new supplier
  const addSupplier = async (supplier) => {
    try {
      const response = await api.post("/suppliers", supplier);
      setSuppliers((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add supplier:", error);
    }
  };

  // Edit an existing supplier
  const editSupplier = async (updatedSupplier) => {
    try {
      const response = await api.put(
        `/suppliers/${updatedSupplier.id}`,
        updatedSupplier
      );
      setSuppliers((prev) =>
        prev.map((supplier) =>
          supplier.id === updatedSupplier.id
            ? response.data
            : supplier
        )
      );
    } catch (error) {
      console.error("Failed to edit supplier:", error);
    }
  };

  // Delete a supplier
  const deleteSupplier = async (id) => {
    try {
      await api.delete(`/suppliers/${id}`);
      setSuppliers((prev) => prev.filter((supplier) => supplier.id !== id));
    } catch (error) {
      console.error("Failed to delete supplier:", error);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  return (
    <SuppliersContext.Provider
      value={{
        suppliers,
        addSupplier,
        editSupplier,
        deleteSupplier,
      }}
    >
      {children}
    </SuppliersContext.Provider>
  );
};

export default SuppliersProvider;
