import React, { useState } from "react";
import Modal from "../../components/common/Modal"; // Reusable Modal Component
import ReusableTable from "../../components/common/ReusableTable"; // Reusable Table Component
import TaskForm from "../../components/forms/TaskForm"; // Reusable Task Form Component

const DailyTasks = ({
  sectionTitle = "Daily Tasks",
  isProjectSpecific = false,
  projectName = "",
  projectId = null,
}) => {
  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add"); // "Add" or "Edit"
  const [selectedTask, setSelectedTask] = useState(null); // For editing

  // Add/Edit Task Handler
  const handleAddEditSubmit = (formData) => {
    if (modalMode === "Add") {
      setTasks([
        ...tasks,
        {
          id: tasks.length + 1,
          projectId,
          createdDate: new Date().toISOString().split("T")[0],
          ...formData,
        },
      ]);
    } else {
      setTasks(
        tasks.map((task) => (task.id === selectedTask.id ? { ...formData } : task))
      );
    }
    closeModal();
  };

  // Delete Task Handler
  const handleDelete = (id) => {
    setTasks(tasks.filter((task) => task.id !== id));
  };

  const openModal = (mode, task = null) => {
    setModalMode(mode);
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
  };

  // Table columns
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Assigned To", accessor: "assignedTo" },
    { Header: "Work", accessor: "work" },
    { Header: "Task Title", accessor: "taskTitle" },
    { Header: "Task Due Date", accessor: "taskDueDate" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <span
          className={`px-3 py-1 rounded ${
            value === "Pending"
              ? "bg-yellow-200 text-yellow-800"
              : "bg-green-200 text-green-800"
          }`}
        >
          {value}
        </span>
      ),
    },
    { Header: "Description", accessor: "description" },
    { Header: "Created By", accessor: "createdBy" },
    { Header: "Created Date", accessor: "createdDate" },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value, row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => openModal("Edit", row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(value)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-xl shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">{sectionTitle}</h1>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          onClick={() => openModal("Add")}
        >
          Add New Task
        </button>
      </div>
      <ReusableTable columns={columns} data={tasks} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalMode === "Add" ? "Add New Task" : "Edit Task"}
        >
          <TaskForm
            onSubmit={handleAddEditSubmit}
            onCancel={closeModal}
            initialValues={
              selectedTask || {
                assignedTo: "",
                work: isProjectSpecific ? projectName : "",
                taskTitle: "",
                taskDueDate: "",
                status: "Pending",
                description: "",
                activities: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default DailyTasks;
