import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const WorkTypesContext = createContext();

const WorkTypesProvider = ({ children }) => {
  const [workTypes, setWorkTypes] = useState([]);

  // Fetch all work types
  const fetchWorkTypes = async () => {
    try {
      const response = await api.get("/work-types");
      setWorkTypes(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Failed to fetch work types:", error);
    }
  };

  // Add a new work type
  const addWorkType = async (workType) => {
    try {
      const response = await api.post("/work-types", workType);
      setWorkTypes((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add work type:", error);
    }
  };

  // Edit an existing work type
  const editWorkType = async (workType) => {
    try {
      const response = await api.put(`/work-types/${workType.id}`, workType);
      setWorkTypes((prev) =>
        prev.map((item) => (item.id === workType.id ? response.data : item))
      );
    } catch (error) {
      console.error("Failed to edit work type:", error);
    }
  };

  // Delete a work type
  const deleteWorkType = async (id) => {
    try {
      await api.delete(`/work-types/${id}`);
      setWorkTypes((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete work type:", error);
    }
  };

  useEffect(() => {
    fetchWorkTypes();
  }, []);

  return (
    <WorkTypesContext.Provider
      value={{
        workTypes,
        addWorkType,
        editWorkType,
        deleteWorkType,
      }}
    >
      {children}
    </WorkTypesContext.Provider>
  );
};

export default WorkTypesProvider;
