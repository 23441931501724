import React, { useContext, useState } from "react";
import { WorkTypesContext } from "../../context/Admin/WorkTypesContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import WorkTypeForm from "../../components/forms/WorkTypeForm";

const WorkTypes = () => {
  const { workTypes, addWorkType, editWorkType, deleteWorkType } =
    useContext(WorkTypesContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWorkType, setSelectedWorkType] = useState(null);

  const columns = [
    { Header: "Work Type ID", accessor: (_row, i) => i + 1 },
    { Header: "Work Type Name", accessor: "workTypeName" },
    { Header: "Item Code", accessor: "itemCode" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddClick = () => {
    setSelectedWorkType(null);
    setIsModalOpen(true);
  };

  const handleEdit = (workType) => {
    setSelectedWorkType(workType);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this work type?")) {
      deleteWorkType(id);
    }
  };

  const handleFormSubmit = (workType) => {
    if (selectedWorkType) {
      editWorkType({ ...selectedWorkType, ...workType });
    } else {
      addWorkType(workType);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Work Types</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add New Work Type
        </button>
      </div>
      <ReusableTable columns={columns} data={workTypes} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={selectedWorkType ? "Edit Work Type" : "Add New Work Type"}
        >
          <WorkTypeForm
            onSubmit={handleFormSubmit}
            onCancel={() => setIsModalOpen(false)}
            initialValues={
              selectedWorkType || {
                workTypeName: "",
                itemCode: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default WorkTypes;
