import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const TaxTypesContext = createContext();

const TaxTypesProvider = ({ children }) => {
  const [taxTypes, setTaxTypes] = useState([]);

  // Fetch all tax types
  const fetchTaxTypes = async () => {
    try {
      const response = await api.get("/tax-types");
      setTaxTypes(response.data || []);
    } catch (error) {
      console.error("Failed to fetch tax types:", error);
    }
  };

  // Add a new tax type
  const addTaxType = async (tax) => {
    try {
      const totalGst =
        (parseFloat(tax.cgst) || 0) +
        (parseFloat(tax.sgst) || 0) +
        (parseFloat(tax.igst) || 0);

      const response = await api.post("/tax-types", { ...tax, totalGst });
      setTaxTypes((prevTaxTypes) => [...prevTaxTypes, response.data]);
    } catch (error) {
      console.error("Failed to add tax type:", error);
    }
  };

  // Edit an existing tax type
  const editTaxType = async (updatedTax) => {
    try {
      const totalGst =
        (parseFloat(updatedTax.cgst) || 0) +
        (parseFloat(updatedTax.sgst) || 0) +
        (parseFloat(updatedTax.igst) || 0);

      const response = await api.put(`/tax-types/${updatedTax.id}`, {
        ...updatedTax,
        totalGst,
      });

      setTaxTypes((prevTaxTypes) =>
        prevTaxTypes.map((tax) =>
          tax.id === updatedTax.id ? response.data : tax
        )
      );
    } catch (error) {
      console.error("Failed to edit tax type:", error);
    }
  };

  // Delete a tax type
  const deleteTaxType = async (id) => {
    try {
      await api.delete(`/tax-types/${id}`);
      setTaxTypes((prevTaxTypes) => prevTaxTypes.filter((tax) => tax.id !== id));
    } catch (error) {
      console.error("Failed to delete tax type:", error);
    }
  };

  useEffect(() => {
    fetchTaxTypes();
  }, []);

  return (
    <TaxTypesContext.Provider
      value={{ taxTypes, addTaxType, editTaxType, deleteTaxType }}
    >
      {children}
    </TaxTypesContext.Provider>
  );
};

export default TaxTypesProvider;
