import React, { useContext, useState, useMemo, useEffect } from "react";
import { AssetContext } from "../../context/Admin/AssetContext"; // Import the context
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import AssetForm from "../../components/forms/AssetForm";

const Assets = () => {
  const { assets, addAsset, editAsset, deleteAsset } = useContext(AssetContext); // Use context
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  // Log assets whenever they change
  useEffect(() => {
    console.log("Assets data received from context:", assets);
  }, [assets]);

  // Prepare data for table
  const transformedAssets = useMemo(
    () =>
      assets.map((asset) => ({
        id: asset.id,
        assetName: asset.assetName,
        description: asset.description,
        quantity: asset.quantity,
        unitCost: parseFloat(asset.unitCost),
        totalCost: parseFloat(asset.totalCost),
        purchasedOn: asset.purchasedOn,
        creation: asset.creation,
      })),
    [assets]
  );

  // Define table columns
  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "id" },
      { Header: "Asset Name", accessor: "assetName" },
      { Header: "Description", accessor: "description" },
      { Header: "Quantity", accessor: "quantity" },
      { Header: "Unit Cost", accessor: "unitCost" },
      { Header: "Total Cost", accessor: "totalCost" },
      { Header: "Purchased On", accessor: "purchasedOn" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  // Handle Add button click
  const handleAdd = () => {
    setSelectedAsset(null);
    setIsModalOpen(true);
  };

  // Handle Edit button click
  const handleEdit = (asset) => {
    setSelectedAsset(asset);
    setIsModalOpen(true);
  };

  // Handle Delete button click
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this asset?")) {
      deleteAsset(id);
    }
  };

  // Handle Form Submission
  const handleFormSubmit = (assetDetails) => {
    if (!validateAssetDetails(assetDetails)) {
      alert("Please fill all required fields and ensure they are valid.");
      return;
    }

    if (selectedAsset) {
      editAsset({ ...selectedAsset, ...assetDetails });
    } else {
      addAsset(assetDetails);
    }

    setIsModalOpen(false);
  };

  // Validate form fields
  const validateAssetDetails = (details) => {
    return (
      details.assetName &&
      details.description &&
      Number.isInteger(Number(details.quantity)) &&
      Number(details.quantity) > 0 &&
      !isNaN(Number(details.unitCost)) &&
      Number(details.unitCost) > 0
    );
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Assets</h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add Asset
        </button>
      </div>

      {/* ReusableTable */}
      <ReusableTable columns={columns} data={transformedAssets} />

      {/* Modal for Add/Edit Asset */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={selectedAsset ? "Edit Asset" : "Add Asset"}
        width="w-1/2"
      >
        <AssetForm
          initialValues={selectedAsset}
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Assets;
