import React, { useState, useEffect } from "react";

const ServiceForm = ({ onSubmit, onCancel, initialValues }) => {
  // Default form values
  const defaultValues = {
    name: "",
    isActive: true,
    ...initialValues, // Override with initialValues if provided
  };

  const [formData, setFormData] = useState(defaultValues);
  const [errors, setErrors] = useState({}); // For form validation errors

  // Update formData when initialValues change
  useEffect(() => {
    setFormData({ ...defaultValues, ...initialValues });
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear errors on change
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleToggle = () => {
    setFormData((prev) => ({
      ...prev,
      isActive: !prev.isActive,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Service name is required.";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Row: Service Name and Status */}
      <div className="flex items-center space-x-6">
        {/* Service Name */}
        <div className="flex-1">
          <label className="block text-sm font-medium">Service Name</label>
          <input
            type="text"
            name="name"
            className={`w-full border rounded p-2 ${
              errors.name ? "border-red-500" : "border-gray-300"
            }`}
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter service name"
          />
          {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
        </div>

        {/* Active Status with Toggle */}
        <div className="flex items-center space-x-4">
          <label className="block text-sm font-medium">Status</label>
          <div
            className={`w-16 h-8 flex items-center rounded-full p-1 cursor-pointer transition-colors ${
              formData.isActive ? "bg-green-500" : "bg-gray-300"
            }`}
            onClick={handleToggle}
          >
            <div
              className={`w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                formData.isActive ? "translate-x-8" : "translate-x-0"
              }`}
            ></div>
          </div>
        </div>
      </div>

      {/* Horizontal Rule */}
      <hr className="my-4 border-gray-300" />

      {/* Buttons: Submit and Cancel */}
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ServiceForm;
