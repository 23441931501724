import React, { useContext, useState } from "react";
import { WorkLocationsContext } from "../../context/Admin/WorkLocationsContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import AreaForm from "../../components/forms/AreaForm";

const WorkLocations = () => {
  const { locations, addLocation, editLocation, deleteLocation } =
    useContext(WorkLocationsContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);

  const columns = [
    { Header: "ID", accessor: (_row, i) => i + 1 },
    { Header: "Area Name", accessor: "areaName" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddClick = () => {
    setSelectedArea(null);
    setIsModalOpen(true);
  };

  const handleEdit = (area) => {
    setSelectedArea(area);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this area?")) {
      deleteLocation(id);
    }
  };

  const handleFormSubmit = (area) => {
    if (selectedArea) {
      editLocation({ ...selectedArea, ...area });
    } else {
      addLocation(area);
    }
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedArea(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Work Locations</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 shadow-md"
        >
          Add New Area
        </button>
      </div>

      <ReusableTable columns={columns} data={locations} />

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedArea ? "Edit Area" : "Add New Area"}
        >
          <AreaForm
            onSubmit={handleFormSubmit}
            onCancel={closeModal}
            initialValues={selectedArea || { areaName: "" }}
          />
        </Modal>
      )}
    </div>
  );
};

export default WorkLocations;
