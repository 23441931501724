import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api2"; // Axios instance for API calls

export const ExpenseBillsContext = createContext();

const ExpenseBillsProvider = ({ children }) => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all expense bills
  const fetchExpenseBills = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get("/expense-bills");
      setBills(response.data || []);
    } catch (err) {
      setError("Failed to fetch expense bills.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Add a new expense bill
  const addExpenseBill = async (bill) => {
    setError(null);
    try {
      const formData = createFormData(bill);
      const response = await api.post("/expense-bills", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setBills((prevBills) => [...prevBills, response.data]);
    } catch (err) {
      setError("Failed to add expense bill.");
      console.error(err);
    }
  };

  // Edit an expense bill
  const editExpenseBill = async (bill) => {
    setError(null);
    try {
      const formData = createFormData(bill);
      const response = await api.put(`/expense-bills/${bill.billId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setBills((prevBills) =>
        prevBills.map((b) => (b.billId === bill.billId ? response.data : b))
      );
    } catch (err) {
      setError("Failed to edit expense bill.");
      console.error(err);
    }
  };

  // Delete an expense bill
  const deleteExpenseBill = async (billId) => {
    setError(null);
    try {
      await api.delete(`/expense-bills/${billId}`);
      setBills((prevBills) => prevBills.filter((b) => b.billId !== billId));
    } catch (err) {
      setError("Failed to delete expense bill.");
      console.error(err);
    }
  };

  // Helper function to create FormData
  const createFormData = (bill) => {
    const formData = new FormData();

    // Append regular fields
    Object.entries(bill).forEach(([key, value]) => {
      if (key === "transactionDetails") {
        // Append nested transactionDetails
        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          formData.append(`transactionDetails[${nestedKey}]`, nestedValue || "");
        });
      } else if (key === "uploadBillCopy") {
        // Append file
        if (value) {
          formData.append(key, value);
        }
      } else {
        formData.append(key, value || "");
      }
    });

    return formData;
  };

  useEffect(() => {
    fetchExpenseBills();
  }, []);

  return (
    <ExpenseBillsContext.Provider
      value={{
        bills,
        loading,
        error,
        addExpenseBill,
        editExpenseBill,
        deleteExpenseBill,
      }}
    >
      {children}
    </ExpenseBillsContext.Provider>
  );
};

export default ExpenseBillsProvider;
