import React, { useContext, useMemo, useState } from "react";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import SecurityDepositForm from "../../components/forms/SecurityDepositForm";
import { SecurityDepositsContext } from "../../context/Admin/SecurityDepositsContext";

const SecurityDeposits = ({ projectName }) => {
  const { deposits, addDeposit, editDeposit, deleteDeposit } =
    useContext(SecurityDepositsContext); // Use context
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [selectedDeposit, setSelectedDeposit] = useState(null);

  // Filter deposits if projectName is provided
  const filteredDeposits = useMemo(() => {
    if (!projectName) return deposits;
    return deposits.filter((item) => item.projectName === projectName);
  }, [deposits, projectName]);
  

  const columns = useMemo(
    () => [
      { Header: "Sr No", accessor: "srNo" },
      { Header: "Date", accessor: "date" },
      { Header: "Deposit Amount", accessor: "depositAmount" },
      { Header: "Retention Money", accessor: "retentionMoney" },
      { Header: "Income Tax", accessor: "incomeTax" },
      { Header: "GST/TDS", accessor: "gstTds" },
      { Header: "Project Name", accessor: "projectName" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedDeposit(null);
    setIsModalOpen(true);
  };

  const handleEdit = (deposit) => {
    setModalMode("Edit");
    setSelectedDeposit(deposit);
    setIsModalOpen(true);
  };


  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this deposit?")) {
      deleteDeposit(id);
  
      // Optionally force a filtered state update for better UX
      setSelectedDeposit(null);
    }
  };
  

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      addDeposit(formData);
    } else if (modalMode === "Edit") {
      editDeposit({ ...selectedDeposit, ...formData });
    }
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          {projectName
            ? `Security Deposits for ${projectName}`
            : "Security Deposits"}
        </h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Security Deposit
        </button>
      </div>

      <ReusableTable columns={columns} data={filteredDeposits} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={
          modalMode === "Add" ? "Add New Security Deposit" : "Edit Security Deposit"
        }
        width="w-1/2"
      >
        <SecurityDepositForm
          initialValues={selectedDeposit || {}} // Ensure it doesn't pass `null` or `undefined`
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
        />

      </Modal>
    </div>
  );
};

export default SecurityDeposits;
