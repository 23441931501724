import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const BankDetailsContext = createContext();

const BankDetailsProvider = ({ children }) => {
  const [bankDetails, setBankDetails] = useState([]);

  // Fetch all bank details
  const fetchBankDetails = async () => {
    try {
      const response = await api.get("/bank-details");
      setBankDetails(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Failed to fetch bank details:", error);
    }
  };

  // Add a new bank detail
  const addBankDetail = async (bank) => {
    try {
      const response = await api.post("/bank-details", bank);
      setBankDetails((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add bank detail:", error);
    }
  };

  // Edit an existing bank detail
  const editBankDetail = async (bank) => {
    try {
      const response = await api.put(`/bank-details/${bank.id}`, bank);
      setBankDetails((prev) =>
        prev.map((item) => (item.id === bank.id ? response.data : item))
      );
    } catch (error) {
      console.error("Failed to edit bank detail:", error);
    }
  };

  // Delete a bank detail
  const deleteBankDetail = async (id) => {
    try {
      await api.delete(`/bank-details/${id}`);
      setBankDetails((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete bank detail:", error);
    }
  };

  useEffect(() => {
    fetchBankDetails();
  }, []);

  return (
    <BankDetailsContext.Provider
      value={{
        bankDetails,
        addBankDetail,
        editBankDetail,
        deleteBankDetail,
      }}
    >
      {children}
    </BankDetailsContext.Provider>
  );
};

export default BankDetailsProvider;
