import React, { useContext, useState } from "react";
import { ExpenseBillsContext } from "../../context/Admin/ExpenseBillsContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import ExpenseBillForm from "../../components/forms/ExpenseBillForm";

const ExpenseBills = () => {
  const { bills, addExpenseBill, editExpenseBill, deleteExpenseBill } =
    useContext(ExpenseBillsContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);

  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Bill ID", accessor: "billId" },
    { Header: "Bill Date", accessor: "billDate" },
    { Header: "Expense Head", accessor: "expenseHead" },
    { Header: "Bill No", accessor: "billNo" },
    { Header: "Consignee", accessor: "consignee" },
    { Header: "GST No", accessor: "gstNo" },
    { Header: "Payable Amount", accessor: "payableAmount" },
    { Header: "Total Paid", accessor: "totalPaid" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded shadow hover:bg-blue-600"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded shadow hover:bg-red-600"
            onClick={() => handleDelete(row.original.billId)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddClick = () => {
    setSelectedBill(null);
    setIsModalOpen(true);
  };

  const handleEdit = (bill) => {
    setSelectedBill(bill);
    setIsModalOpen(true);
  };

  const handleDelete = (billId) => {
    if (window.confirm("Are you sure you want to delete this bill?")) {
      deleteExpenseBill(billId);
    }
  };

  const handleFormSubmit = (bill) => {
    if (selectedBill) {
      editExpenseBill({ ...selectedBill, ...bill });
    } else {
      addExpenseBill(bill);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Expense Bills</h2>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New Bill
        </button>
      </div>

      <ReusableTable columns={columns} data={bills} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={selectedBill ? "Edit Expense Bill" : "Add New Expense Bill"}
        width="w-3/4"
      >
        <ExpenseBillForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          initialValues={selectedBill || {}}
        />
      </Modal>
    </div>
  );
};

export default ExpenseBills;
