import React, { useContext, useMemo, useState } from "react";
import { EMDContext } from "../../context/Admin/EMDContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import EMDForm from "../../components/forms/EMDForm";

const EMD = ({ projectName }) => {
  const { emdRecords, addEmd, editEmd, deleteEmd } = useContext(EMDContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [selectedEMD, setSelectedEMD] = useState(null);

  const filteredData = useMemo(() => {
    return projectName
      ? emdRecords.filter((item) => item.project === projectName)
      : emdRecords;
  }, [emdRecords, projectName]);

  const columns = useMemo(
    () => [
      { Header: "Sl No", accessor: "srNo" },
      { Header: "Date", accessor: "date" },
      { Header: "Project", accessor: "project" },
      { Header: "Client", accessor: "client" },
      { Header: "EMD Amount", accessor: "emdAmount" },
      { Header: "TDS", accessor: "tds" },
      { Header: "GST TDS", accessor: "gstTds" },
      { Header: "Creation", accessor: "creation" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => handleDelete(row.original.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleAdd = () => {
    setModalMode("Add");
    setSelectedEMD(null);
    setIsModalOpen(true);
  };

  const handleEdit = (emd) => {
    setModalMode("Edit");
    setSelectedEMD(emd);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this EMD record?")) {
      deleteEmd(id);
    }
  };

  const handleFormSubmit = (formData) => {
    if (modalMode === "Add") {
      addEmd(formData);
    } else if (modalMode === "Edit") {
      editEmd({ ...selectedEMD, ...formData });
    }
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          {projectName ? `EMD Records for ${projectName}` : "All EMD Records"}
        </h2>
        <button
          onClick={handleAdd}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Add New EMD
        </button>
      </div>

      <ReusableTable columns={columns} data={filteredData} />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalMode === "Add" ? "Add New EMD" : "Edit EMD"}
        width="w-3/4"
      >
        <EMDForm
          onSubmit={handleFormSubmit}
          onCancel={() => setIsModalOpen(false)}
          projectName={projectName || ""}
          initialValues={selectedEMD || {}}
        />
      </Modal>
    </div>
  );
};

export default EMD;
