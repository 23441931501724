import React, { useState, useEffect } from "react";

const ExpenseBillForm = ({ initialValues = {}, onSubmit, onCancel }) => {
  // Define form fields dynamically
  const billFields = [
    { name: "expenseType", label: "Expense Type", type: "select", options: ["Work Expense", "Office Expense"], default: "Work Expense" },
    { name: "workName", label: "Work Name", type: "text" },
    { name: "expenseHead", label: "Expense Head", type: "text" },
    { name: "billNo", label: "Bill No", type: "text" },
    { name: "billDate", label: "Bill Date", type: "date" },
    { name: "gstPercent", label: "GST %", type: "number" },
    { name: "billAmount", label: "Bill Amount", type: "number" },
    { name: "consignee", label: "Consignee", type: "text" },
    { name: "payableAmount", label: "Payable Amount", type: "number" },
    { name: "gstNo", label: "GST No", type: "text" },
    { name: "billDescription", label: "Bill Description", type: "textarea" },
    { name: "billRemarks", label: "Bill Remarks", type: "textarea" },
    { name: "uploadBillCopy", label: "Upload Bill Copy", type: "file" },
  ];

  const transactionFields = [
    { name: "paidAmount", label: "Paid Amount", type: "number" },
    { name: "paidDate", label: "Paid Date", type: "date" },
    { name: "paidFromAcc", label: "Paid From Account", type: "select", options: ["Cash Account", "Bank Account"], default: "Cash Account" },
    { name: "paymentMode", label: "Payment Mode", type: "text" },
    { name: "trReferenceNo", label: "Transaction Reference No", type: "text" },
    { name: "trReferenceDate", label: "Transaction Reference Date", type: "date" },
    { name: "transactionRemarks", label: "Transaction Remarks", type: "textarea" },
    { name: "billBalanceAmt", label: "Bill Balance Amount", type: "number" },
  ];

  const defaultFormData = billFields.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: field.type === "select" ? field.default || "" : "",
    }),
    {
      transactionDetails: transactionFields.reduce(
        (acc, field) => ({
          ...acc,
          [field.name]: field.type === "select" ? field.default || "" : "",
        }),
        {}
      ),
    }
  );

  const [formDetails, setFormDetails] = useState(defaultFormData);
  const [errors, setErrors] = useState({}); // For validation errors

  useEffect(() => {
    if (initialValues) {
      setFormDetails({
        ...defaultFormData,
        ...initialValues,
        transactionDetails: {
          ...defaultFormData.transactionDetails,
          ...initialValues.transactionDetails,
        },
      });
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;
    setFormDetails((prev) => ({
      ...prev,
      [name]: newValue,
    }));
    if (errors[name]) setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleTransactionChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      transactionDetails: {
        ...prev.transactionDetails,
        [name]: value,
      },
    }));
    if (errors[name]) setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    billFields.forEach((field) => {
      if (!formDetails[field.name] && field.type !== "file" && field.type !== "textarea") {
        newErrors[field.name] = `${field.label} is required.`;
      }
    });
    transactionFields.forEach((field) => {
      if (!formDetails.transactionDetails[field.name] && field.type !== "textarea") {
        newErrors[field.name] = `${field.label} is required.`;
      }
    });
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();

    // Append bill fields to FormData
    billFields.forEach((field) => {
      const value = formDetails[field.name];

      if (field.type === "file") {
        if (value) {
          formData.append(field.name, value); // Append file if it exists
        }
      } else {
        formData.append(field.name, value || ""); // Append other fields, defaulting to an empty string
      }
    });

    // Append transaction details as a nested structure
    Object.entries(formDetails.transactionDetails).forEach(([key, value]) => {
      formData.append(`transactionDetails[${key}]`, value || ""); // Default to an empty string if value is null or undefined
    });

    // Trigger the onSubmit callback
    if (onSubmit) {
      onSubmit(formData); // Pass FormData to the parent component
    }
  };


  const renderField = (field, isTransaction = false) => {
    const value = isTransaction
      ? formDetails.transactionDetails[field.name]
      : formDetails[field.name];
    const handleChangeFn = isTransaction ? handleTransactionChange : handleChange;

    switch (field.type) {
      case "select":
        return (
          <select
            name={field.name}
            value={value}
            onChange={handleChangeFn}
            className={`w-full border rounded p-2 ${errors[field.name] ? "border-red-500" : "border-gray-300"
              }`}
          >
            {field.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "textarea":
        return (
          <textarea
            name={field.name}
            value={value}
            onChange={handleChangeFn}
            className={`w-full border rounded p-2 ${errors[field.name] ? "border-red-500" : "border-gray-300"
              }`}
          ></textarea>
        );
      case "file":
        return (
          <input
            type="file"
            name={field.name}
            onChange={handleChangeFn}
            className="w-full border border-gray-300 rounded p-2"
          />
        );
      default:
        return (
          <input
            type={field.type}
            name={field.name}
            value={value}
            onChange={handleChangeFn}
            className={`w-full border rounded p-2 ${errors[field.name] ? "border-red-500" : "border-gray-300"
              }`}
          />
        );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-white">
      <h3 className="text-lg font-bold">Bill Details</h3>
      <div className="grid grid-cols-3 gap-4">
        {billFields.map((field) => (
          <div key={field.name}>
            <label className="block text-sm font-medium">{field.label}</label>
            {renderField(field)}
            {errors[field.name] && (
              <p className="text-red-500 text-sm">{errors[field.name]}</p>
            )}
          </div>
        ))}
      </div>

      <h3 className="text-lg font-bold">Transaction Details</h3>
      <div className="grid grid-cols-3 gap-4">
        {transactionFields.map((field) => (
          <div key={field.name}>
            <label className="block text-sm font-medium">{field.label}</label>
            {renderField(field, true)}
            {errors[field.name] && (
              <p className="text-red-500 text-sm">{errors[field.name]}</p>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-end space-x-4 mt-6">
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-500 text-white px-4 py-2 rounded shadow hover:bg-gray-600"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ExpenseBillForm;
