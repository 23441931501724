import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Replace with your actual API instance

export const SalesCustomersContext = createContext();

const SalesCustomersProvider = ({ children }) => {
  const [customers, setCustomers] = useState([]);

  // Fetch all customers
  const fetchCustomers = async () => {
    try {
      const response = await api.get("/customers");
      setCustomers(response.data || []);
    } catch (error) {
      console.error("Failed to fetch customers:", error);
    }
  };

  // Add a new customer
  const addCustomer = async (customer) => {
    try {
      const response = await api.post("/customers", customer);
      setCustomers((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add customer:", error);
    }
  };

  // Edit an existing customer
  const editCustomer = async (updatedCustomer) => {
    try {
      const response = await api.put(`/customers/${updatedCustomer.id}`, updatedCustomer);
      setCustomers((prev) =>
        prev.map((customer) =>
          customer.id === updatedCustomer.id ? response.data : customer
        )
      );
    } catch (error) {
      console.error("Failed to edit customer:", error);
    }
  };

  // Delete a customer
  const deleteCustomer = async (id) => {
    try {
      await api.delete(`/customers/${id}`);
      setCustomers((prev) => prev.filter((customer) => customer.id !== id));
    } catch (error) {
      console.error("Failed to delete customer:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <SalesCustomersContext.Provider
      value={{
        customers,
        addCustomer,
        editCustomer,
        deleteCustomer,
      }}
    >
      {children}
    </SalesCustomersContext.Provider>
  );
};

export default SalesCustomersProvider;
