import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const HolidaysContext = createContext();

const HolidaysProvider = ({ children }) => {
  const [holidays, setHolidays] = useState([]);

  // Fetch all holidays
  const fetchHolidays = async () => {
    try {
      const response = await api.get("/holidays");
      setHolidays(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Failed to fetch holidays:", error);
    }
  };

  // Add a new holiday
  const addHoliday = async (holiday) => {
    try {
      const response = await api.post("/holidays", holiday);
      setHolidays((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Failed to add holiday:", error);
    }
  };

  // Edit an existing holiday
  const editHoliday = async (holiday) => {
    try {
      const response = await api.put(`/holidays/${holiday.id}`, holiday);
      setHolidays((prev) =>
        prev.map((item) => (item.id === holiday.id ? response.data : item))
      );
    } catch (error) {
      console.error("Failed to edit holiday:", error);
    }
  };

  // Delete a holiday
  const deleteHoliday = async (id) => {
    try {
      await api.delete(`/holidays/${id}`);
      setHolidays((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete holiday:", error);
    }
  };

  useEffect(() => {
    fetchHolidays();
  }, []);

  return (
    <HolidaysContext.Provider
      value={{
        holidays,
        addHoliday,
        editHoliday,
        deleteHoliday,
      }}
    >
      {children}
    </HolidaysContext.Provider>
  );
};

export default HolidaysProvider;
