import React, { useContext, useState } from "react";
import { TaxTypesContext } from "../../context/Admin/TaxTypesContext";
import ReusableTable from "../../components/common/ReusableTable";
import Modal from "../../components/common/Modal";
import TaxForm from "../../components/forms/TaxForm";

const TaxTypes = () => {
  const { taxTypes, addTaxType, editTaxType, deleteTaxType } =
    useContext(TaxTypesContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTax, setSelectedTax] = useState(null);

  // Table columns
  const columns = [
    { Header: "Sl No", accessor: (_row, i) => i + 1 },
    { Header: "Tax Name", accessor: "taxName" },
    { Header: "Total GST %", accessor: "totalGst" },
    { Header: "CGST %", accessor: "cgst" },
    { Header: "SGST %", accessor: "sgst" },
    { Header: "IGST %", accessor: "igst" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            title="Edit Tax"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
            title="Delete Tax"
            onClick={() => handleDelete(row.original.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleAddClick = () => {
    setSelectedTax(null);
    setIsModalOpen(true);
  };

  const handleEdit = (tax) => {
    setSelectedTax(tax);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this tax type?")) {
      deleteTaxType(id);
    }
  };

  const handleFormSubmit = (tax) => {
    if (selectedTax) {
      editTaxType({ ...selectedTax, ...tax });
    } else {
      addTaxType(tax);
    }
    setIsModalOpen(false);
  };
  

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTax(null);
  };

  return (
    <div className="p-6 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700 mb-6">Tax Types</h1>
        <button
          onClick={handleAddClick}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 shadow-md"
        >
          Add New Tax
        </button>
      </div>

      <ReusableTable columns={columns} data={taxTypes} />

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedTax ? "Edit Tax" : "Add New Tax"}
        >
          <TaxForm
            onSubmit={handleFormSubmit}
            onCancel={closeModal}
            initialValues={
              selectedTax || {
                taxName: "",
                cgst: "",
                sgst: "",
                igst: "",
              }
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default TaxTypes;
