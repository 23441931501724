import React, { createContext, useState, useEffect } from "react";
import api from "../../api/api"; // Axios instance for API calls

export const ClientsContext = createContext();

const ClientsProvider = ({ children }) => {
  const [clients, setClients] = useState([]);

  // Fetch all clients
  const fetchClients = async () => {
    try {
      const response = await api.get("/clients");
      setClients(response.data || []); // Ensure response is an array
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  // Add a new client
  const addClient = async (client) => {
    try {
      const response = await api.post("/clients", client);
      setClients((prev) => [...prev, response.data]);
    } catch (error) {
      console.error("Error adding client:", error);
    }
  };

  // Edit an existing client
  const editClient = async (client) => {
    try {
      const response = await api.put(`/clients/${client.id}`, client);
      setClients((prev) =>
        prev.map((item) => (item.id === client.id ? response.data : item))
      );
    } catch (error) {
      console.error("Error editing client:", error);
    }
  };

  // Delete a client
  const deleteClient = async (id) => {
    try {
      await api.delete(`/clients/${id}`);
      setClients((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <ClientsContext.Provider
      value={{
        clients,
        addClient,
        editClient,
        deleteClient,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

export default ClientsProvider;
